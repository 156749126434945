// Colors
$color-text-primary: #ffa420;
$color-text-secondary: #aa00ff;
$color-text-tertiary: #d8d8d8;
$color-text-danger: #ff3939;
$color-text-success: #3cb371;

$color-primary: #ffa420;
$color-secondary: #aa00ff;
$color-tertiary: #d8d8d8;
$color-danger: #b11e1e;
$color-success: #299c5d;

$color-primary-dark: #67410d;
$color-secondary-dark: #250037;
$color-tertiary-dark: #333333;
$color-danger-dark: #0f0000;
$color-success-dark: #000f00;

$color-nav-hover: #aa00ff69;

$bg-dark: #000000;
$bg-light: #0f0f0f;

$bg-component-dark: #111111;
$bg-component-light: #222222;

$transparent: #00000000;
$color-shadow: #000000A0;

// Borders
$border-primary: 2px solid $color-primary;
$border-secondary: 2px solid $color-secondary; 
$border-tertiary: 2px solid $color-tertiary; 
$border-tertiary-dark: 2px solid $color-tertiary-dark; 
$border-danger: 2px solid $color-danger; 
$border-success: 2px solid $color-success; 

$radius-small: 4px;
$radius-medium: 8px;
$radius-large: 12px;

// Effects
/*Background size is 128, the diagonal is 181, midpoint is 91.5, create a 2px wide center line*/
$bg-line-size: 88.5px;
$bg-line-effect: 92.5px;
/*awkward offset gives the triangle effect*/
$bg-triangle-size: 59.5px;
$bg-triangle-effect: 110.5px;

$primary-gradient: linear-gradient(180deg, black 0%, black 33%, $color-primary-dark 100%);
$secondary-gradient: linear-gradient(180deg, black 0%, black 33%, $color-secondary-dark 100%);
$secondary-gradient-black: linear-gradient(180deg, black 0%, black 100%);

$transition-normal: ease all .35s;
$transition-fast: ease-out all .15s;

// Spacing
$nav-button-size: 44px;
$nav-left-margin: 12px; // padded for the scroll bar
$nav-right-margin: $nav-left-margin + 12px; // just used for spacing
$nav-closed: $nav-button-size + $nav-right-margin + 2px; // 2px border on circle 
$nav-closed-mobile: $nav-button-size + $nav-left-margin + 2px; // 2px border on circle 
$nav-opened: $nav-closed + 200px;

// Sizes
$sm: 420px;
$md: 690px;
$lg: 1100px;

$sm-max: $sm - 1px;
$md-max: $md - 1px;
$lg-max: $lg - 1px;

$text-tiny: 8px;
$text-small: 10px;
$text-medium: 16px;
$text-large: 24px;
$text-huge: 32px;
$text-gargantuan: 40px;