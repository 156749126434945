@import 'variables';

.App {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;

  color: $color-text-tertiary;
  /*Backround fills in the triangle and diamond*/
  background:
      /*Draw the grid*/ linear-gradient(
      45deg,
      $transparent 0px $bg-line-size,
      $bg-light $bg-line-size $bg-line-effect,
      $transparent $bg-line-effect
    ),
    linear-gradient(
      -45deg,
      $transparent 0px $bg-line-size,
      $bg-light $bg-line-size $bg-line-effect,
      $transparent $bg-line-effect
    ),
    /*Draw the black lines making up the triangle and diamond*/
      linear-gradient(
        45deg,
        $transparent 0px $bg-triangle-size,
        $bg-dark $bg-triangle-size $bg-triangle-effect,
        $transparent $bg-line-effect
      ),
    linear-gradient(
      -45deg,
      $transparent 0px $bg-triangle-size,
      $bg-dark $bg-triangle-size $bg-triangle-effect,
      $transparent $bg-line-effect
    );
  /*Backround fills in the triangle and diamond*/
  background-color: $bg-light;
  background-size: 128px 128px;
}

.center-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-width, .fw {
  width: 100% !important;
}

.half-width, .hw {
  width: 50% !important;
}

.min-width, .mw {
  width: min-content !important;
}

.fit-content, .fc {
  width: fit-content !important;
}

.width-sm-max {
  max-width: $sm;
}