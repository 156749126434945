@import 'variables';

.card__error {
  margin-top: 0;
  color: $color-text-danger;
  border: $border-danger;
  border-radius: $radius-medium;
  background: $bg-component-dark;
  box-shadow: 0 0 5px 1px $color-danger, inset 0 0 5px 1px $color-danger;
  padding: 8px 16px;
}

.card__success {
  margin-top: 0;
  color: $color-text-success;
  border: $border-success;
  border-radius: $radius-medium;
  background: $bg-component-dark;
  box-shadow: 0 0 5px 1px $color-success, inset 0 0 5px 1px $color-success;
  padding: 8px 16px;
}

.component-base {
  border: $border-secondary;
  border-radius: $radius-medium;
  background: $bg-component-light;
  margin: 0;
  box-shadow: 0 0 5px 0 $color-secondary;
  color: $color-tertiary;
  padding: 8px 16px;
  font-size: $text-medium;
  transition: $transition-fast;
  outline: $color-secondary;
  width: 100%;

  &.--danger {
    border: $border-danger;
    color: $color-text-danger;
    box-shadow: 0 0 5px 1px $color-danger, inset 0 0 5px 1px $color-danger;
  }
}

.lws-button {
  @extend .component-base;
  width: auto;
  margin: 0;
  color: $color-text-tertiary;

  &:active {
    transform: translateY(4px);
  }

  &:hover {
    color: $color-text-tertiary;
    box-shadow: 0 0 5px 2px $color-secondary, inset 0 0 5px 0 $color-secondary;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.--min-content {
    white-space: nowrap;
    min-width: min-content;
    width: min-content;
  }
}

.lws-input {
  @extend .component-base;
}

.lws-labeled-input {
  @extend .component-base;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;

  &:first-child {
    margin-top: 0 !important;
  }

  label {
    padding: 8px 16px;
    border-right: $border-secondary;
    border-radius: $radius-medium 0 0 $radius-medium;
    background: $bg-component-dark;
    min-width: 40%;
  }

  .lws-input.--labeled,
  .lws-select.--labeled {
    flex-grow: 1;
    padding: 8px 16px;
    background: none;
    border: none;
    color: $color-text-tertiary;
    font-size: $text-medium;
    outline: $color-secondary;
    border-radius: 0 $radius-medium $radius-medium 0;
  }

  &.--min-content {
    white-space: nowrap;
    min-width: min-content;
    width: min-content;

    label {
      min-width: min-content;
    }
    .lws-input.--labeled,
    .lws-select.--labeled {
      min-width: 85px;
    }
  }
}

.lws-text-area {
  @extend .component-base;
  font-family: 'Nunito', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.lws-select {
  @extend .component-base;
}

@media only screen and (max-width: $md-max) {
  .lws-labeled-input {
    flex-direction: column;
    width: 100%;

    label {
      width: 100%;
      border-right: none;
      border-bottom: $border-secondary;
      border-radius: $radius-medium $radius-medium 0 0;
    }

    .lws-input.--labeled,
    .lws-select.--labeled {
      width: 100%;
    }
  }
}
