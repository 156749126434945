@use 'About.scss';
@use 'App.scss';
@use 'BasicComponents.scss';
@use 'ContainerComponents.scss';
@use 'Home.scss';
@use 'NYMap.scss';
@use 'PsoTextSimulator.scss';
@use 'PsoCharacterTracker.scss';
@use 'RapierDiceRoller.scss';
@import 'variables';

html {
  background: $bg-dark;
  color: $color-text-tertiary;
  font-family: 'Nunito', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100dvh;
  width: 100%;
}

a {
  color: $color-text-secondary;

  &:visited {
    color: $color-text-secondary;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Limelight', cursive;
}

nav {
  transition: $transition-normal;
  border-right: $border-secondary;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  height: 100%;
  width: $nav-closed;
  background: $secondary-gradient;
  opacity: 0.5;
  overflow: hidden;
  padding-bottom: 8px;
  direction: ltr;
  z-index: 100;

  hr {
    width: 100%;
    border: 1px solid #333333;
  }

  button.nav-link {
    margin-top: 10px;
    margin-left: $nav-left-margin;

    .nav-link__icon {
      border: none;
      font-size: $text-huge;
      font-weight: 200;
    }
  }

  .nav-inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 100%;
    padding: 0 10px 0 $nav-left-margin;
    margin: 0;
    direction: rtl;
  }

  * {
    scrollbar-color: $color-tertiary $color-tertiary;
    scrollbar-width: thin;
  }

  .nav-link {
    direction: ltr;
    transition: $transition-normal;
    display: grid;
    padding: 0;
    margin: 0;
    grid-template-columns: 44px ($nav-opened - $nav-closed - 18px);
    column-gap: 16px;
    align-items: center;
    background: none;
    border: none;
    border-radius: 22px;
    font-size: $text-medium;
    text-decoration: none;

    .nav-link__icon {
      transition: $transition-normal;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background: $transparent;
      border: $border-secondary;
      font-weight: 700;
      border-radius: 50%;
      text-align: center;
      color: $color-secondary;

      i {
        font-size: 24px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .nav-link__label {
      color: $color-secondary;
      font-weight: 700;
      text-align: left;
    }

    &.--active,
    &.--active:hover {
      .nav-link__icon {
        color: black;
        background: $color-secondary;
        border: 2px solid $transparent;
      }

      .nav-link__label {
        color: black;
      }
    }

    &:hover {
      .nav-link__icon {
        background-color: $color-nav-hover;
      }
    }
  }

  .spacer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    margin: 0;
  }
}

.--nav-open {
  nav {
    opacity: 1;
    width: $nav-opened;
    .nav-link.--active,
    .nav-link.--active:hover {
      background: $color-secondary;
      div {
        border-right: 2px solid black;
      }
    }

    .nav-link {
      &:hover {
        .nav-link__icon {
          background-color: $transparent;
        }
        background-color: $color-nav-hover;
      }
    }
  }
}

main {
  transition: $transition-normal;
  padding: 0 0 0 ($nav-closed);
  min-height: 100%;
  width: 100%;
  display: grid;

  h1 {
    text-align: center;
  }
}

html,
html * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.full-column {
  columns: -1;
}

@media only screen and (max-width: $sm-max) {
  nav {
    border-right: none;
    border-top: $border-secondary;
    height: 100%;
    top: calc(100% - $nav-closed-mobile);
    width: 100%;
    direction: rtl;
    padding: 8px;
    opacity: 1;

    button.nav-link {
      margin-top: 0;
    }

    .nav-link {
      direction: rtl;
      width: 100%;
      grid-template-columns: 44px 1fr;

      .nav-link__label {
        text-align: start;
      }
    }
  }

  main {
    padding: 0 0 ($nav-closed-mobile) 0;
  }

  .--nav-open {
    nav {
      top: 0;
      width: 100%;

      .nav-link.--active,
      .nav-link.--active:hover {
        div {
          border-right: $border-secondary;
          border-left: 2px solid black;
        }
      }

      .nav-inner {
        padding: 0 0 8px 0;
      }
    }
  }
}
