@import 'variables';

.rapier-dice-roller {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'box ctrl'
    'menu menu';

  @media only screen and (max-aspect-ratio: 1) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto;
    grid-template-areas:
      'box'
      'ctrl'
      'menu';
  }

  .rapier-dice-box {
    grid-area: 'box';
  }

  .rapier-dice-controls {
    grid-area: 'ctrl';
    padding: 16px;
    gap: 8px;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 185px;

    @media only screen and (max-aspect-ratio: 1) {
      padding: 8px;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 100%;

      .--min-content {
        white-space: wrap;
        min-width: auto;
        width: auto;
      }
    }
  }

  .rapier-dice-menu {
    grid-area: 'menu';
    padding: 16px;
    gap: 8px;
    display: grid;
    grid-template-columns: auto auto auto auto 1fr;

    @media only screen and (max-aspect-ratio: 1) {
      padding: 8px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .--min-content {
        white-space: wrap;
        min-width: auto;
        width: auto;
      }
    }
  }
}
