@import 'variables';

.pso-text-simulator {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  textarea {
    font-family: 'Gulim';
  }

  .text-box {
    transition: ease all 0.35s;
    margin-top: 32px;
    background: $color-tertiary;
    border-radius: 16px 20px 16px 20px;

    p {
      text-align: left;
      padding: 0;
      color: black;
      font-family: 'Gulim';
      letter-spacing: 2px;
      font-weight: 700;
      margin: 8px 0px;
    }

    &.--message-box {
      transform: skew(-24deg);
      box-shadow: 6px 6px 0 0 black;
      p {
        letter-spacing: 3px;
        font-size: 24px;
        transform: skew(24deg);
        margin: 10px 24px;
      }
    }

    &.--window-message {
      border-radius: 6px;
      padding: 0 8px;
      p {
        letter-spacing: 2px;
        border-radius: 10px;
        max-width: 305px;
        font-size: 16px;
        word-break: break-all;
      }
    }
  }
}

@media only screen and (max-width: $sm-max) {
  .pso-text-simulator {
    .input-section {
      flex-direction: column;
      textarea {
        width: 100%;
      }
      select {
        width: 100%;
      }
    }
  }
}
