@import 'variables';

.lws-card {
  border: $border-primary;
  background: $bg-component-dark;
  border-radius: $radius-large;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 0 10px 2px $color-primary-dark, inset 0 0 20px 2px $color-primary-dark;
  max-height: 90dvh;

  .lws-card__header {
    margin-bottom: 8px;
    padding: 24px 24px 0 24px;
  }

  .lws-card__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    padding: 24px;
  }

  .lws-card__footer {
    padding: 0 24px 24px 24px;
    margin-top: 8px;
    button {
      width: 100%;
    }
  }

  .lws-card__footer, .lws-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 16px;

    h1, h2, h3, h4, h5, h6 {
      width: 100%;
      text-align: center;
      margin: 0;
      flex-grow: 1;
    }
  }
}

.lws-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  &.--hidden {
    display: none;
  }

  .modal__veil {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    z-index: 201;
    background: $color-shadow;
  }

  .modal__card {
    position: relative;
    z-index: 202;
  }
}