@import 'variables';

.home-container {
  width: 100%;
  display: flex;
  padding-bottom: 32px;
  flex-direction: column;
  align-items: center;
}

iframe {
  aspect-ratio: 16 / 9;
  max-width: 620px;
  width: 100%; /* change this to a fixed width, or create a container with a width. */
  height: 100%;
}