@import 'variables';
.ny-map-marker__background {
  position: relative;
  background: white;
  border: 1px solid $color-tertiary-dark;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: ease all .35s;
  z-index: 100;
  &.--active{
    width: 28px;
    height: 28px;
    border: 2px solid $color-tertiary-dark;
  }
}

.ny-map-marker__icon{
  position: absolute;
}

.ny-map-marker__label{
  position: absolute;
  bottom: calc(100% - 8px);
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid $color-tertiary-dark;
  opacity: 0;
  max-width: 0px;
  max-height: 0px;
  height: 0px;
  transition: ease all .35s;
  font-size: 12px;
  width: max-content;
  color: $color-tertiary-dark;
  z-index: 1;
  overflow: hidden;

  &.--active {
    opacity: 1;
    height: auto;
    max-width: 300px;
    max-height: 500px;
  }
}

.ny-map-marker__description{
  @extend .ny-map-marker__label;
  top: calc(100% - 8px);
  bottom: auto;
}