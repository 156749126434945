@import 'variables';
$color-lines: #005a67;
$color-border: #96a6a6;
$color-background: #062f2d;

.pso-character-tracker-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.pso-character-list-wrapper {
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;

  @media only screen and (max-width: $sm-max) {
    grid-template-columns: 1fr;
  }
}

$line-size: 1px;
$spacing: 2px;
$line-value: $line-size + $spacing;
.pso-character-card {
  position: relative;
  background: repeating-linear-gradient(
    $color-background,
    $color-background $spacing,
    $color-lines $spacing + $line-size,
    $color-background $spacing + $line-size + $line-size
  );
  border: 1.3px solid $color-border;
  border-radius: $radius-large;
  padding: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 32px;
  row-gap: 8px;
  text-align: start;
  box-shadow: 0 0 2px 2px #000, inset 0 0 15px 5px #000000;

  span {
    font-size: 16px;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 2px #000000;
  }
}
button.pso-character-card {
  opacity: 0.8;
  &:hover {
    box-shadow: 0 0 4px 2px #ffffff40, inset 0 0 15px 5px #000000;
    opacity: 1;
  }
}

.pso-character-selected-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  gap: 16px;
  padding: 0 16px 16px 16px;

  @media only screen and (max-width: $md-max) {
    grid-template-columns: 1fr;
  }
}

.pso-column-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pso-button {
  border: 1.3px solid $color-border;
  border-radius: 100px;
  background-color: black;
  padding: 6px 6px 2px 6px;
  box-shadow: 0 0 4px 0px black;
  color: white;
  transition: ease all 0.1s;
  width: min-content;
  height: min-content;

  &:disabled {
    opacity: 0.5;
  }

  &.--danger {
    border: 1.3px solid #b11e1e;
  }

  &:active {
    transform: translateY(2px);
  }
}

.pso-character-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  &.--complete{
    span {
      color: $color-success;
    }
  }

  &.--over{
    span {
      color: $color-danger;
    }
  }
}

.pso-character-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pso-divider {
  width: 100%;
  height: 1px;
  background-color: $color-border;
  margin: 8px 0;
  opacity: 0.5;
}

.pso-mats-controls {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
